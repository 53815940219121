<h1>Best 10 Products over all</h1>

<mat-card>
  <mat-card-content>
    <table>
      <tr>
        <th class="product-index">No.</th>
        <th>Name</th>
        <th class="sale-value">Value</th>
        <th>Total</th>
      </tr>
      <tr *ngFor="let purchase of bestPurchases; let i = index">
        <td class="product-index">{{ i + 1 }}</td>
        <td>{{ purchase.product }}</td>
        <td class="sale-value">{{ purchase.value }}</td>
        <td>{{ purchase.total }} €</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>
