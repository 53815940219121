<mat-card class="customer-detail-header">
  <mat-card-content>
    <button
      mat-icon-button
      class="pos-right-upper"
      [matMenuTriggerFor]="headerMenu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #headerMenu="matMenu" xPosition="before">
      <button mat-menu-item (click)="editCustomerName()">edit</button>
    </mat-menu>

    <img src="assets/img/profile.png" />

    <div>
      <h1>{{ firestore.customer.firstName }} {{ firestore.customer.lastName }}</h1>
      <span>{{ firestore.customer.company }}</span>
    </div>
  </mat-card-content>
</mat-card>

<mat-card class="mt-32">
  <button
    mat-icon-button
    class="pos-right-upper"
    [matMenuTriggerFor]="addressMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #addressMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="editCustomerAddress()">edit</button>
  </mat-menu>

  <mat-card-header class="border-bottom-grey">
    <mat-card-title>Contact information</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    {{ firestore.customer.address }}<br />
    {{ firestore.customer.zipCode }} {{ firestore.customer.city }}<br />
    <a href="mailto:{{ firestore.customer.email }}">{{ firestore.customer.email }}</a
    ><br />
    <a href="tel:{{ firestore.customer.telephone }}">{{ firestore.customer.telephone }}</a>
  </mat-card-content>
</mat-card>

<mat-card class="mt-32">
  <button
    mat-icon-button
    class="pos-right-upper"
    [matMenuTriggerFor]="sellingMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>

  <mat-menu #sellingMenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="openSellingDialog()">Direkt sell</button>
  </mat-menu>

  <mat-card-header class="border-bottom-grey">
    <mat-card-title>Puchases</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <table class="purchases-table">
      <tr>
        <th class="product-index">No.</th>
        <th class="sel-date">Date</th>
        <th>Product</th>
        <th class="sel-value">Value</th>
        <th class="product-price">Price per unit</th>
        <th>Price</th>
      </tr>
      <tr *ngFor="let purchase of firestore.CustomerPurchases; let i = index">
        <td class="product-index">{{ i + 1 }}</td>
        <td class="sel-date">{{ getDate(purchase.date) }}</td>
        <td>{{ purchase.name }}</td>
        <td class="sel-value">{{ purchase.value }}</td>
        <td class="product-price">{{ purchase.price }}€</td>
        <td>{{ purchase.price * purchase.value }}€</td>
      </tr>
      <tr>
        <td class="product-index"></td>
        <td class="sel-date"></td>
        <td class="sel-value"></td>
        <td class="product-price"></td>
        <td>in total</td>
        <td>{{ getTotalVolume() }}€</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card class="mt-32">
  <mat-card-header class="border-bottom-grey">
    <mat-card-title>Notes</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Note</mat-label>
        <textarea
          [disabled]="firestore.loading"
          [(ngModel)]="firestore.customer.note"
          matInput
          placeholder="Write some notes"
          style="min-height: 160px;"
        ></textarea>
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <button mat-raised-button [disabled]="firestore.loading" (click)="firestore.customer.note = ''; firestore.saveNote('abc')" class="mr-16">Clear notes</button>
      <button mat-raised-button [disabled]="firestore.loading" (click)="firestore.saveNote('abc')">Save</button>
    </div>
  </mat-card-content>
</mat-card>
