<mat-card>
  <mat-card-content>
    <table class="customer-table">
      <tr>
        <th>Name</th>
        <th class="customer-mail">E-Mail</th>
        <th class="customer-tel">Telephone</th>
        <th class="customer-city">City</th>
      </tr>
      <tr
        *ngFor="let customer of getCustomer(); let i = index"
        [routerLink]="['/customer/' + customer.id]"
      >
        <td>{{ customer.firstName }} {{ customer.lastName }}</td>
        <td class="customer-mail">{{ customer.email }}</td>
        <td class="customer-tel">{{ customer.telephone }}</td>
        <td class="customer-city">{{ customer.city }}</td>
        <td>
          <button mat-icon-button (click)="stopEvent($event)" [matMenuTriggerFor]="customerOverviewMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #customerOverviewMenu="matMenu">
            <a mat-menu-item href="mailto:{{ customer.email }}">send Mail</a>
            <a mat-menu-item href="tel:{{ customer.telephone }}">call</a>
            <button mat-menu-item (click)="openDeleteDialog(i)">delete</button>
          </mat-menu>
        </td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

<button
  class="add-customer-btn"
  mat-fab
  color="primary"
  matTooltip="Add new Customer"
  [matTooltipPosition]="'above'"
  (click)="openDialog()"
>
  <mat-icon>add</mat-icon>
</button>
