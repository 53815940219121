<h1>Last 10 Sales</h1>

<mat-card>
  <mat-card-content>
    <table>
      <tr>
        <th class="sell-index">No.</th>
        <th>Name</th>
        <th class="sell-date">Date</th>
        <th class="product-name">Product</th>
        <th>Total</th>
      </tr>
      <tr *ngFor="let purchase of lastPurchases">
        <td class="sell-index">{{ purchase.index }}</td>
        <td>{{ purchase.name }}</td>
        <td class="sell-date">{{ purchase.date }}</td>
        <td class="product-name">{{ purchase.product }}</td>
        <td>{{ purchase.total }} €</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>


