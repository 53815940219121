<mat-drawer-container class="mat-drawer-container" autosize [hasBackdrop]="backdrop">
  <mat-drawer #drawer class="sidenav" [opened]="sidenavOpen" mode="side">
    <div class="logo-container">
      <img class="logo" src="assets/img/logo.png" />
    </div>

    <ul class="menu-items">
      <li routerLink=""><mat-icon>fact_check</mat-icon>Dashboard</li>
      <li routerLink="customer"><mat-icon>perm_identity</mat-icon>Customers</li>
      <li routerLink="products"><mat-icon>store</mat-icon>Products</li>
    </ul>
    <button mat-raised-button (click)="drawer.toggle()" class="menu-btn" *ngIf="!sidenavOpen">
      Close
    </button>
  </mat-drawer>

  <div class="example-sidenav-content">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="toolbar">
        <mat-icon class="menu-icon" (click)="drawer.toggle()"
          >menu</mat-icon
        >
        <span>Simple CRM</span>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <div class="router-container">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
