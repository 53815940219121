<div class="dialog-content">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <h1 mat-dialog-title>Edit address informations</h1>
  <div mat-dialog-content class="of-visible">
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>E-Mail</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.email"
          matInput
          placeholder="mail@domain.com"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Telephone</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.telephone"
          matInput
          placeholder="00499876123456"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Adsress</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.address"
          matInput
          placeholder="Musterstr. 1"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="mr-16">
        <mat-label>Zip-Code</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.zipCode"
          matInput
          placeholder="12345"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.city"
          matInput
          placeholder="Musterhausen"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [disabled]="loading" mat-dialog-close>Cancel</button>
    <button
      mat-button
      [disabled]="loading"
      mat-dialog-close
      (click)="saveEdits()"
    >
      Save
    </button>
  </div>
</div>
