<div class="dialog-content">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  <h1 mat-dialog-title>Edit general informations</h1>
  <div mat-dialog-content class="of-visible">
    <div>
      <mat-form-field appearance="outline" class="mr-16">
        <mat-label>First Name</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.firstName"
          matInput
          placeholder="Max"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="customer.lastName"
          matInput
          placeholder="Mustermann"
        />
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Company</mat-label>
      <input
        [disabled]="loading"
        [(ngModel)]="customer.company"
        matInput
        placeholder="Example (Ltd.)"
      />
      <!-- <mat-hint>Hint</mat-hint> -->
    </mat-form-field>
    <div>
      <mat-form-field appearance="outline" class="full-width">
        <mat-label>Birthdate</mat-label>
        <input
          [disabled]="loading"
          [(ngModel)]="birthDate"
          matInput
          placeholder="MM/DD/YYYY"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <!-- <mat-hint>Hint</mat-hint> -->
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button mat-button [disabled]="loading" mat-dialog-close>Cancel</button>
      <button
        mat-button
        [disabled]="loading"
        mat-dialog-close
        (click)="saveEdits()"
      >
        Save
      </button>
    </div>
  </div>
</div>
