<div class="dialog-content">
  <mat-progress-bar *ngIf="firestore.loading" mode="indeterminate"></mat-progress-bar>
  <h1 mat-dialog-title>Add Product</h1>
  <div mat-dialog-content class="of-visible">
      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>Name</mat-label>
              <input [disabled]="firestore.loading" [(ngModel)]="product.name"
                  matInput placeholder="Gaming PC">
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>Description</mat-label>
              <textarea [disabled]="firestore.loading" [(ngModel)]="product.description" matInput
                  placeholder="Write something about the Product"></textarea>
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Prozessor</mat-label>
            <input [disabled]="firestore.loading" [(ngModel)]="product.prozessor" matInput
            placeholder="AMD Ryzen 9 7900X">
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
    </div>

      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>Mainboard</mat-label>
              <input [disabled]="firestore.loading" [(ngModel)]="product.mainboard" matInput
              placeholder="Gigabyte Aorus Master">
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>GPU</mat-label>
            <input [disabled]="firestore.loading" [(ngModel)]="product.gpu" matInput
            placeholder="Nvidia Gforce RTX 4090">
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
    </div>

      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>RAM</mat-label>
              <input [disabled]="firestore.loading" [(ngModel)]="product.ram" matInput
                  placeholder="32 GB DDR5 6200MHZ">
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>SSD</mat-label>
              <input [disabled]="firestore.loading" [(ngModel)]="product.ssd" matInput
                  placeholder="Samsung Evo 960">
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
          <mat-form-field appearance="outline" class="full-width">
              <mat-label>Power supply</mat-label>
              <input [disabled]="firestore.loading" [(ngModel)]="product.power" matInput
                  placeholder="Gigabyte 1000W">
              <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>Price</mat-label>
            <input [disabled]="firestore.loading" [(ngModel)]="product.price" matInput
                placeholder="1900">
            <!-- <mat-hint>Hint</mat-hint> -->
        </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
      <button mat-button [disabled]="firestore.loading" mat-dialog-close>Cancel</button>
      <button mat-button [disabled]="firestore.loading" mat-dialog-close
          (click)="saveEditProduct()">Save</button>
  </div>
</div>
