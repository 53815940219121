<div class="header-container">
  <h1>Dashboard</h1>
  <div class="form-field">
    <mat-form-field appearance="outline">
      <mat-label>Choose a year</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        placeholder="YYYY"
        [(ngModel)]="currentYear"
        [min]="minDate"
        [max]="maxDate"
      />
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        startView="multi-year"
        (yearSelected)="chosenYearHandler($event, picker)"
        panelClass="example-month-picker"
      ></mat-datepicker>
    </mat-form-field>
    <button mat-icon-button (click)="chooseYear()">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</div>

<div class="upper-container">
  <mat-card>
    <mat-card-content>
      <app-monthly-sales-chart></app-monthly-sales-chart>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <app-proportional-sales></app-proportional-sales>
    </mat-card-content>
  </mat-card>
</div>

<div class="lower-container mt-32">
  <mat-card>
    <mat-card-content>
      <app-last-sales></app-last-sales>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content>
      <app-best-products></app-best-products>
    </mat-card-content>
  </mat-card>
</div>
